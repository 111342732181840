'use client';
import * as React from 'react';
import { Exo_2 } from 'next/font/google';
import Image from 'next/image';
import { useState } from 'react';
import { Menu, X } from 'react-feather';
import { Button } from './ui/button';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { Github } from 'lucide-react';

const exo2 = Exo_2({ weight: ['300', '400', '700'], subsets: ['latin'] });

interface NavbarProps {
  full?: boolean;
}

const Navbar: React.FunctionComponent<NavbarProps> = ({ full }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav
      className={cn(
        'h-20 flex backdrop-blur-md px-4 mx-auto border-b border-background w-full'
      )}
    >
      <div
        className={cn(
          'mx-auto w-full max-w-7xl flex items-center justify-between'
        )}
      >
        <Link
          href="https://fashn.ai/"
          className="hover:opacity-80 text-3xl"
          aria-label="homepage"
        >
          <div className="flex items-center gap-2">
            <Image
              src={'/logo-enhanced.svg'}
              alt={'fashn-logo'}
              width={'32'}
              height={'32'}
            />
            <h2 className={cn(exo2.className, 'font-semibold')}>
              FASHN<span className="font-light">AI</span>
            </h2>
          </div>
        </Link>
        <div className="flex gap-10">
          <div className="h-20 w-[1px] bg-background hidden xl:block"></div>
          <ul
            className={`hidden items-center gap-6 xl:flex border-background text-primary text-sm uppercase font-medium ${
              isMenuOpen ? 'flex' : 'hidden'
            }`}
          >
            <li>
              <Link
                href="https://fashn.ai/#how-it-works"
                aria-label="how it works"
                className="hover:opacity-60 transition ease-in-out duration-200"
              >
                How It Works
              </Link>
            </li>
            <li>
              <Link
                href="https://fashn.ai/#features"
                aria-label="features"
                className="hover:opacity-60 transition ease-in-out duration-200"
              >
                Features
              </Link>
            </li>
            <li>
              <Link
                href="https://fashn.ai/#pricing"
                aria-label="pricing"
                className="hover:opacity-60 transition ease-in-out duration-200"
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                href="/blog"
                aria-label="blog"
                className="hover:opacity-60 transition ease-in-out duration-200"
              >
                Blog
              </Link>
            </li>
            <li>
              <a
                href="https://github.com/fashn-AI"
                aria-label="github"
                className="flex items-center hover:opacity-60 transition ease-in-out duration-200"
                target="_blank"
              >
                <Github className="h-4 w-4 mr-1" />
                GitHub
              </a>
            </li>
            <li>
              <a
                href={'https://docs.fashn.ai'}
                target="_blank"
                aria-label="api"
                className="hover:opacity-60 transition ease-in-out duration-200"
              >
                Documentation
              </a>
            </li>
            <li>
              <a href={'https://app.fashn.ai'}>
                <Button variant={'special'} size={'sm'}>
                  Log In
                </Button>
              </a>
            </li>
          </ul>
        </div>
        {/* Mobile */}
        <div className="flex gap-8 xl:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="xl:hidden block"
          >
            {' '}
            {isMenuOpen ? (
              <X size={30} aria-label="close-nav-menu" />
            ) : (
              <Menu size={30} aria-label="nav-menu" />
            )}
          </button>
          <div
            className={`${
              isMenuOpen ? 'block' : 'hidden'
            } absolute p-6 mt-[56px] right-0 w-full xl:relative xl:w-auto xl:p-0 xl:mt-0 xl:flex text-primary bg-foreground`}
          >
            <ul
              className={`flex flex-col items-center justify-center gap-4 uppercase font-medium`}
            >
              <li>
                <Link
                  href="https://fashn.ai/#how-it-works"
                  onClick={handleLinkClick}
                  aria-label="how it works"
                >
                  How It Works
                </Link>
              </li>
              <li>
                <Link
                  href="https://fashn.ai/#features"
                  onClick={handleLinkClick}
                  aria-label="features"
                >
                  Features
                </Link>
              </li>
              <li>
                <Link
                  href="https://fashn.ai/#pricing"
                  onClick={handleLinkClick}
                  aria-label="pricing"
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link href="/blog" onClick={handleLinkClick} aria-label="blog">
                  Blog
                </Link>
              </li>
              <li>
                <a
                  href="https://github.com/fashn-AI"
                  aria-label="github"
                  className="flex items-center"
                  target="_blank"
                >
                  <Github className="h-4 w-4 mr-1" />
                  GitHub
                </a>
              </li>
              <li>
                <a
                  href="https://docs.fashn.ai"
                  aria-label="documentation"
                  target="_blank"
                >
                  Documentation
                </a>
              </li>
              <li>
                <Link
                  href="https://fashn.ai/#faq"
                  onClick={handleLinkClick}
                  aria-label="frequently-asked-questions"
                >
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
